@import '../../mixins';

.quick-buy {
    width: 100%;

  &__sum {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;

    & .text {
      color: var(--text-dark-secondary);

      & .quick-buy {
        &__strong {
          color: var(--text-dark-primary);
          font-size: rem(25);
          font-weight: 500;

          @include mediaBigDesktop {
            font-size: big(25);
          }

          @include mediaLaptop {
            font-size: rem(20);
          }

          @include mediaTablet {
            font-size: rem(16);
          }
        }
      }
    }
  }
}
